@use "../../App.scss" as *;
.home_item_cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10rem 0;
  position: relative;
  overflow: hidden;
  padding-bottom: 3rem;
  @include breack_down(medium) {
    // min-height: 48rem;
    margin: 5rem 0;
  }
  h2 {
    margin-bottom: 80px;
    color: $mainBlue;
    letter-spacing: 6px;
    font-size: 30px;
    text-align: center;
  }
}
.min_height_for_webinar {
  @include breack_down(medium) {
    min-height: 42rem;
  }
}
.min_height_for_course {
  @include breack_down(medium) {
    min-height: 50rem;
  }
}
.see_all_cont {
  display: flex;
  align-items: center;
  position: absolute;
  gap: 10px;
  bottom: 0;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s;
  @include breack_down(medium) {
    margin: 0 auto;
    bottom: 0px;
    text-align: center;
  }
  p {
    color: $mainGreen;
    font-size: 20px;
    letter-spacing: 3px;
  }
  img {
    filter: invert(56%) sepia(62%) saturate(7196%) hue-rotate(131deg)
      brightness(97%) contrast(101%);
  }
  &:hover {
    transform: scale(1.08);
  }
}

.active {
  @include breack_down(medium) {
    left: 5vw;
  }
}
.next_web {
  @include breack_down(medium) {
    left: 95vw;
  }
}
.next {
  @include breack_down(medium) {
    left: 90vw;
  }
}
.past {
  @include breack_down(medium) {
    left: -100vw;
  }
}
.home_courses_cont {
  display: flex;
  gap: 2rem;

  .home_courses_item {
    @include breack_up(medium) {
      opacity: 0;
    }
    @include breack_down(medium) {
      width: 80vw;
      position: absolute;
      min-height: 23.3rem;
      height: 75%;
    }
    background-color: #fff;
    border-radius: 5px;
    transition: 0.3s ease-in-out;
    text-decoration: none;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    width: 27vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    &:hover {
      transform: scale(1.02) !important;
    }

    img {
      border-radius: 5px 5px 0 0;
      width: 100%;
      height: 15rem;
    }
    .course_text {
      padding: 0.5rem 1rem 1rem 1rem;

      h3 {
        font-size: 22px;
        color: $mainGrey;
        text-align: center;
      }
      p {
        color: $mainGrey;
        span {
          color: $mainBlue;
        }
      }
      .course_yeses {
        margin-top: 1rem;
        .course_yes {
          display: flex;
          align-items: center;
          gap: 0.4rem;
          p {
            color: $mainGrey;
          }
          img {
            width: 1.3rem;
            height: 1.3rem;
            filter: invert(58%) sepia(51%) saturate(7170%) hue-rotate(166deg)
              brightness(101%) contrast(101%);
          }
        }
      }
    }
  }
}
.home_blog_cont {
  display: flex;
  gap: 30px;
  @include breack_down(large) {
    flex-direction: column;
  }
  .home_blog_item {
    @include breack_down(large) {
      width: 80vw;
    }
    width: 25vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
    }
    .home_blogs_title {
      color: $mainBlue;
    }
    position: relative;
    overflow: hidden;

    .blogs_hover {
      transition: 0.5s ease-in-out;
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      background: rgba(255, 255, 255, 0.34);
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(8.1px);
      -webkit-backdrop-filter: blur(8.1px);
      border: 1px solid rgba(255, 255, 255, 0.3);
      bottom: -100%;
      h3 {
        color: $mainBlue;
        text-align: center;
      }
    }
    .blogs_hover_active {
      bottom: 0;
    }
    .details_btn_blogs {
      text-decoration: none;
      background-color: $mainBlue;
      padding: 0.7rem 1.5rem;
      color: #fff;
      border-radius: 20px;
      transition: 0.5s ease-in-out;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}
.home_webinars_cont {
  margin-bottom: 5rem;
  display: flex;
  gap: 4rem;
  .home_webinars_item {
    transition: 0.3s ease-in-out;
    text-decoration: none;
    width: 27vw;
    position: relative;
    padding: 1rem;
    background-color: #fff;
    box-shadow: rgba(0, 0, 194, 0.164) 3px 3px 5px;
    border-radius: 20px;
    &:hover {
      transform: scale(1.02);
    }
    @include breack_down(medium) {
      width: 80vw;
      position: absolute;
      min-height: 22rem;
      // padding: 0.5rem;
    }
    .webinar_content {
      border-radius: 10px;
      height: 100%;
      position: relative;
      z-index: 5;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include breack_down(medium) {
        min-height: 24rem;
      }
      img {
        border-radius: 10px;
        width: 100%;
        height: 17rem;
        margin-bottom: 1rem;
        @include breack_down(medium) {
          height: 11rem;
          // object-fit: cover;
        }
      }
      h3 {
        font-size: 22px;
        color: #0096c7;
        text-align: center;
        @include breack_down(medium) {
          font-size: 20px;
        }
        span {
          color: $mainBlue;
        }
      }
      .webinar_text {
        padding: 1rem 0;
        .webinar_dates {
          margin-top: 1rem;
          p {
            color: #999999;
            font-weight: 600;
            @include breack_down(medium) {
              font-size: 14px;
            }
            span {
              font-weight: 400;
              margin-left: 1rem;
              color: #007999;
            }
          }
        }
        .home_web_btn {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 1rem;
          padding-top: 1rem;
          border-top: 1px solid $mainBlue;
          color: #0096c7;
          h4 {
            max-width: 60%;
            @include breack_down(medium) {
              font-size: 14px;
            }
          }
          button {
            background-color: $mainBlue;
            color: #fff;
            padding: 0.7rem;
            border: none;
            cursor: pointer;
            border-radius: 7px;
            transition: 0.3s;
            @include breack_down(medium) {
              padding: 0.4rem;
              height: fit-content;
            }
            &:hover {
              background-color: #fff;
              outline: 1px solid $mainBlue;
              color: $mainBlue;
            }
          }
        }
      }
    }
  }
}
.points {
  @include breack_up(small) {
    display: none;
  }
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 1rem;
}
.point {
  width: 15px;
  height: 15px;
  background-color: $mainBlue;
  border-radius: 50%;
  border: 3px solid $mainBlue;
}
.open_point {
  background-color: white;
}
.home_service_cont {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 2rem;
  @include breack_down(large) {
    grid-template-columns: 1fr 1fr;
  }
  @include breack_down(medium) {
    grid-template-columns: 1fr;
  }
  .hoem_service_item {
    width: 22vw;
    opacity: 0;
    @include breack_down(large) {
      width: 40vw;
    }
    @include breack_down(medium) {
      width: 80vw;
    }
    position: relative;
    text-decoration: none;
    display: flex;
    justify-content: center;
    text-align: center;
    img {
      width: 100%;
    }
    .home_service_title {
      transition: 0.4s ease-in-out;
      position: absolute;
      color: #fff;
      bottom: 0;
      z-index: 100;
    }
    .home_service_title_active {
      bottom: 50%;
      transform: translateY(50%);
      color: $mainBlue;
    }
    .service_bacgound {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      background: rgba(255, 255, 255, 0.34);
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(8.1px);
      -webkit-backdrop-filter: blur(8.1px);
      border: 1px solid rgba(255, 255, 255, 0.3);
      transition: 0.7s ease-in-out;
    }
    .opacity_1 {
      opacity: 1;
    }
  }
}

.webinar_back {
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 10px;
  opacity: 0;
  box-shadow: rgba(100, 100, 111, 0.07) 2px 2px 5px;
}
// weibinar first item
.webinar_content_0 {
  opacity: 1;
  animation: fadeInUp 0.3s forwards;
}
.webinar_back_0_0 {
  top: 10px;
  left: 10px;
  z-index: 4;
  animation: fadeInUp 0.3s forwards 0.3s;
}
.webinar_back_0_1 {
  top: 20px;
  left: 20px;
  z-index: 3;
  animation: fadeInUp 0.3s forwards 0.6s;
}
.webinar_back_0_2 {
  top: 30px;
  left: 30px;
  z-index: 2;
  animation: fadeInUp 0.3s forwards 0.9s;
}
.webinar_back_0_3 {
  top: 40px;
  left: 40px;
  z-index: 1;
  animation: fadeInUp 0.3s forwards 1.2s;
}
// webinar second item
.webinar_content_1 {
  opacity: 1;
  animation: fadeInUp 0.3s forwards 0.3s;
}
.webinar_back_1_0 {
  top: 10px;
  left: 10px;
  z-index: 4;
  animation: fadeIn 0.3s forwards 0.6s;
}
.webinar_back_1_1 {
  top: 20px;
  left: 20px;
  z-index: 3;
  animation: fadeIn 0.3s forwards 0.9s;
}
.webinar_back_1_2 {
  top: 30px;
  left: 30px;
  z-index: 2;
  animation: fadeIn 0.3s forwards 1.2s;
}
.webinar_back_1_3 {
  top: 40px;
  left: 40px;
  z-index: 1;
  animation: fadeIn 0.3s forwards 1.5s;
}
// webinar third item
.webinar_content_2 {
  opacity: 1;
  animation: fadeInUp 0.3s forwards 0.6s;
}
.webinar_back_2_0 {
  top: 10px;
  left: 10px;
  z-index: 4;
  animation: fadeIn 0.3s forwards 0.9s;
}
.webinar_back_2_1 {
  top: 20px;
  left: 20px;
  z-index: 3;
  animation: fadeIn 0.3s forwards 1.2s;
}
.webinar_back_2_2 {
  top: 30px;
  left: 30px;
  z-index: 2;
  animation: fadeIn 0.3s forwards 1.5s;
}
.webinar_back_2_3 {
  top: 40px;
  left: 40px;
  z-index: 1;
  animation: fadeIn 0.3s forwards 1.8s;
}

.service_animation_0 {
  animation: flipInX 0.7s forwards 0.3s linear, opacity forwards 0.7s 0.3s;
}
.service_animation_1 {
  animation: flipInX 0.7s forwards 0.6s linear, opacity forwards 0.7s 0.6s;
}
.service_animation_2 {
  animation: flipInX 0.7s forwards 0.9s linear, opacity forwards 0.7s 0.9s;
}
.service_animation_3 {
  animation: flipInX 0.7s forwards 1.2s linear, opacity forwards 0.7s 1.2s;
}
.courses_viwe_0 {
  animation: fadeInLeft forwards 0.5s 0.5s;
}
.courses_viwe_1 {
  animation: fadeInDown forwards 0.5s 0.5s;
}
.courses_viwe_2 {
  animation: fadeInRight forwards 0.5s 0.5s;
}
.blogs_viwe {
  opacity: 0;
}
.blogs_viwe_0 {
  animation: flipInY 1s forwards, opacity forwards 1s;
}
.blogs_viwe_1 {
  animation: flipInY 1s 0.3s forwards, opacity forwards 0.3s 1s;
}
.blogs_viwe_2 {
  animation: flipInY 1s 0.6s forwards, opacity forwards 0.6s 1s;
}
@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

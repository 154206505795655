@use "../App.scss" as *;

.course_rating {
  border-top: 1px solid rgb(114, 146, 252);
  display: flex;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
  h5 {
    color: $mainBlue;
  }
}
.rating {
  display: flex;
  align-items: center;
  img {
    filter: invert(25%) sepia(80%) saturate(1879%) hue-rotate(186deg)
      brightness(97%) contrast(102%);
  }
}

@use "../../App.scss" as *;

.webinars_cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;

  margin-top: 10rem;

  @include breack_down(medium) {
    margin-top: 8rem;
  }
  h2 {
    color: $mainBlue;
  }
}

.webinars_div_cont {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  gap: 5rem;
  @include breack_down(large) {
    grid-template-columns: 1fr;
  }

  .webinars_item {
    background-color: #fff;
    border-radius: 5px;
    transition: 0.3s;
    text-decoration: none;
    box-shadow: 5px 5px 10px rgba(2, 40, 255, 0.336);
    width: 35vw;
    @include breack_up(medium) {
      &:hover {
        transform: scale(1.05);
      }
    }
    @include breack_down(large) {
      width: 80vw;
    }
    @include breack_down(medium) {
      width: 90vw;
    }
    cursor: pointer;
    img {
      border-radius: 7px 7px 0 0;
      width: 100%;
      height: 14rem;
      @include breack_down(medium) {
        height: 10rem;
      }
    }
    .webinars_text {
      padding: 0.5rem 1rem 1rem 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      h3 {
        margin-bottom: 1.3rem;
        font-size: 22px;
        color: #000000;
        font-family: "Barlow", sans-serif;
        font-weight: 700;
        text-align: center;
      }
      .webinar_dates {
        @include breack_up(medium) {
          display: flex;
          width: 100%;
          justify-content: space-between;
        }

        p {
          color: #2d303d;
          span {
            font-weight: 700;
          }
        }
      }
      .btn_cont {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1rem;
        border-top: 1px #2d303d solid;
        padding-top: 1rem;
        width: 100%;
        p {
          font-size: 18px;
          color: #2d303d;
          @include breack_down(large) {
            font-size: 14px;
          }
        }
        button {
          background-color: $mainBlue;
          color: #fff;
          border: none;
          padding: 1rem 2rem;
          border-radius: 10px;
          transition: 0.5s;
          cursor: pointer;
          @include breack_down(large) {
            padding: 0.5rem 1rem;
          }
          &:hover {
            background-color: #fff;
            color: $mainBlue;
            outline: 1px solid $mainBlue;
          }
        }
      }
    }
  }
}

.single_webinar_cont {
  margin-top: 10rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include breack_down(large) {
    align-items: center;
    margin-bottom: 4rem;
  }
  .single_webinar_title {
    color: $mainBlue;
  }
}
.web_sec_cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include breack_down(medium) {
    // flex-direction: column-reverse;
    margin-bottom: 4rem;
  }
}
.img_cont_web {
  width: 100%;
  .webinar_img_main {
    width: 100%;
    @include breack_up(medium) {
      object-fit: cover;
      width: 100%;
      height: 70vh;
    }
  }
}

.webinar_info_title {
  display: flex;
  justify-content: space-between;
  gap: 3rem;
  background-color: #fff;
  margin: 5rem 0;
  width: 65vw;
  padding: 4rem 2rem;
  border-radius: 4px;
  box-shadow: 0px 0px 120px #acb2d1;
  // background-image: url("../../images/wb.jpg");

  background: rgb(76, 251, 63);
  background: radial-gradient(
    circle,
    rgba(95, 172, 89, 0.315) 0%,
    rgba(62, 149, 199, 0.582) 100%
  );
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  @include breack_down(large) {
    flex-direction: column;
    align-items: center;
  }
  @include breack_down(medium) {
    width: 100vw;
    margin: 1rem 0;
    padding: 2rem 1rem;
    box-shadow: 0px 0px 0px #acb2d1;
  }
  h2 {
    width: 60%;
    font-size: 40px;
    @include breack_down(large) {
      width: 100%;
      text-align: center;
      font-size: 28px;
    }
  }
  .weinar_title_dates {
    display: flex;
    gap: 1rem;
    border-radius: 10px;
    height: fit-content;
    font-weight: 500;

    .w_date {
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid #eee;
      border-radius: 10px 10px 0 0;
      font-size: 0.875rem;
      min-width: 5rem;
      height: 100%;
      .w_date_month {
        color: #683fbe;
        background-color: #ede8f7;
        padding: 0.375rem;
        border-radius: 10px 10px 0 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .w_date_start_days {
        padding: 5px;
        font-size: 1.5rem;
        color: #2c155f;
        background-color: #ffffff;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .w_time {
    background-color: #fafafa;
    padding: 10px 4rem 10px 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p {
      font-size: 0.875rem;
    }
    h5 {
      font-size: 1rem;
    }
  }
}

.webinar_success {
  position: fixed;
  top: 0;
  left: 0;
  background: linear-gradient(to right, $mainBlue, $mainGreen);
  width: 100%;
  height: 100%;
  z-index: 100;
  padding: 12rem;
  @include breack_down(medium) {
    padding: 8rem 4rem 0 4rem;
    text-align: center;
  }
  .success_close {
    float: right;
    cursor: pointer;
    transition: 0.3s;
    img {
      width: 3rem;
      height: 3rem;
    }
    &:hover {
      opacity: 0.8;
      transform: scale(1.02);
    }
  }
  .success_text {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    color: #fff;
    padding-top: 5rem;
    text-align: center;
    h2 {
      font-size: 35px;
      @include breack_down(large) {
        font-size: 20px;
      }
    }
    h3 {
      font-size: 25px;
      @include breack_down(large) {
        font-size: 18px;
      }
    }
  }
  a {
    // margin-left: 1rem;
    text-decoration: none;
    color: #25d366;
    background-color: #fff;
    padding: 0.2rem 0.5rem;
    border-radius: 5px;
    font-size: 25px;
    cursor: pointer;
    @include breack_down(large) {
      font-size: 16px;
    }
  }
}
.webinar_desc_title {
  text-align: center;
  margin-bottom: 2.5rem;
  display: block;
  color: $mainBlue;
  background-color: rgba(164, 241, 255, 0.308);
  padding: 1rem;
  letter-spacing: 1px;
  font-weight: 700;
  font-size: 2rem;
  @include breack_down(medium) {
    margin-bottom: 0rem;
  }
}
.webinar_sticky {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  padding: 0 2rem;
  max-width: 100%;
  @include breack_down(large) {
    flex-direction: column;
    align-items: center;
    padding: 0 1rem;
  }
  @include breack_down(medium) {
    flex-direction: column;
    padding: 0 0.2rem;
  }
  .webinar_info {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    width: 65vw;
    @include breack_down(large) {
      width: 100%;
      gap: 1rem;
    }
  }

  .webinar_form {
    position: -webkit-sticky;
    position: sticky;
    top: 7rem;
    width: 35vw;
    background-color: #fff;
    border-radius: 10px;
    height: fit-content;
    padding: 2rem;
    z-index: 2;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    @include breack_down(large) {
      position: static;
      width: 70%;
    }
    @include breack_down(medium) {
      width: 90%;
      padding: 1rem;
    }
    h2 {
      margin-bottom: 2rem;
    }
    form {
      display: flex;
      flex-direction: column;
      gap: 4rem;
      .webinar_input {
        display: flex;
        align-items: center;
        gap: 2rem;
        position: relative;
        input {
          padding: 1.4rem 0.5rem;
          border-radius: 5px;
          border: none;
          outline: $seeAll 1px solid;
          width: 100%;
          color: $mainBlue;
          @include breack_down(large) {
            padding: 0.7rem;
          }
        }
        label {
          @include breack_down(large) {
            font-size: 12px;
          }
          color: $mainBlue;
          font-size: 18px;
        }
        .error_message {
          color: red;
          position: absolute;
          width: fit-content;
          bottom: 0%;
          right: 0%;
          transform: translate(0%, 110%);
          font-size: 15px;
          @include breack_down(medium) {
            font-size: 12px;
          }
        }
      }
      .webinar_btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 2rem;
        button {
          transition: 0.4s;
          background-color: $mainRed;
          color: #fff;
          padding: 1rem 3rem;
          border: none;
          cursor: pointer;
          border-radius: 10px;
          &:hover {
            background-color: #fff;
            color: $mainRed;
            outline: 1px solid $mainRed;
          }
          @include breack_down(medium) {
            padding: 0.5rem 1.5rem;
          }
        }
        .buton_icon {
          display: flex;
          align-items: center;
          gap: 1rem;
          color: $mainRed;
          cursor: pointer;
          transition: 0.4s;
          @include breack_down(medium) {
            font-size: 12px;
            gap: 0.2rem;
          }
          &:hover {
            opacity: 0.7;
          }
          img {
            width: 2rem;
            height: 2rem;
            filter: invert(27%) sepia(92%) saturate(5458%) hue-rotate(346deg)
              brightness(92%) contrast(103%);
            @include breack_down(medium) {
              width: 1rem;
              height: 1rem;
            }
          }
        }
      }
    }
  }
}

.disapled_btn {
  cursor: no-drop !important;
  background-color: $mainRed !important;
  outline: 1px solid $mainRed !important;
  &:hover {
    background-color: #fff !important;
    color: $mainRed !important;
  }
}

.recomended_courses {
  width: 100%;
  overflow: hidden;
  gap: 2rem;
  h2 {
    margin: 3rem 0;
    width: 100%;
    text-align: center;
  }
  .rec_relative_cont {
    position: relative;
    left: 5vw;
    @include breack_down(medium) {
      left: 10vw;
    }
  }
  .recomended_courses {
    width: 100%;
    overflow: hidden;
    gap: 2rem;
    h2 {
      margin: 3rem 0;
      width: 100%;
      text-align: center;
    }
    .rec_relative_cont {
      position: relative;
      left: 5vw;
      @include breack_down(medium) {
        left: 10vw;
      }
    }
    .courses_rec_cont {
      display: flex;
      gap: 5rem;
      position: relative;
      transition: 1s;
      width: fit-content;
      .courses_rec_item {
        background-color: #fff;
        text-decoration: none;
        border-radius: 5px;
        width: 25vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        img {
          width: 100%;
          height: 12rem;
        }
        color: $mainBlue;
        @include breack_down(large) {
          width: 40vw;
        }
        @include breack_down(medium) {
          width: 80vw;
        }
        .rec_yeses {
          .rec_yes {
            display: flex;
            img {
              width: 1.2rem;
              height: 1.2rem;
            }
          }
        }
        .course_rec_text {
          h3 {
            text-align: center;
            margin: 1rem 0;
          }
        }
        img {
          width: 100%;
          border-radius: 5px 5px 0 0;
        }
      }
    }

    .rec_btns {
      width: 100%;
      justify-content: center;
      margin: 3rem 0;
      display: flex;
      gap: 2rem;
    }
  }
  .courses_rec_cont {
    display: flex;
    gap: 5rem;
    position: relative;
    transition: 1s;
    width: fit-content;
    .courses_rec_item {
      background-color: #fff;
      text-decoration: none;
      border-radius: 5px;
      width: 25vw;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      img {
        width: 100%;
        height: 12rem;
      }
      color: $mainBlue;
      @include breack_down(large) {
        width: 40vw;
      }
      @include breack_down(medium) {
        width: 80vw;
      }
      .rec_yeses {
        .rec_yes {
          display: flex;
          img {
            width: 1.2rem;
            height: 1.2rem;
          }
        }
      }
      .course_rec_text {
        h3 {
          text-align: center;
          margin: 1rem 0;
        }
      }
      img {
        width: 100%;
        border-radius: 5px 5px 0 0;
      }
    }
  }

  .rec_btns {
    width: 100%;
    justify-content: center;
    margin: 3rem 0;
    display: flex;
    gap: 2rem;
  }
}
.html_text_webinar {
  width: 100%;
  @include breack_down(large) {
    padding: 0.5rem;
  }
  // h4 {
  //   margin-bottom: 1rem;
  // }
  // h2 {
  //   margin-bottom: 1rem;
  // }
  p {
    color: rgb(0, 0, 0);
    margin: 1rem 0;
    line-height: 20px;
  }
  h3,
  h2,
  h1 {
    color: $mainBlue;
    margin: 1rem 0;
    @include breack_down(medium) {
      text-align: center;
      margin: 0.5rem 0;
    }
  }
  ul {
    margin-left: 3rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: black;
    @include breack_down(large) {
      margin-left: 1.5rem;
    }
    li {
      margin: 10px 0;
    }
  }
  img {
    width: 100% !important;
    height: auto !important;
  }
}

@use "../../App.scss" as *;

footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include breack_down(large) {
    flex-direction: column;
    padding: 3rem;
    gap: 3rem;
  }

  padding: 3rem 7rem 5rem 3rem;
  background-color: $footer;
}
.footer_logo {
  img {
    width: 15rem;
  }
}
.footer_links {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  row-gap: 2rem;
  a {
    transition: 0.3s;
    text-decoration: none;
    color: #fff;
    &:hover {
      color: $mainGreen;
    }
  }
}
.footer_media {
  display: flex;
  gap: 1.4rem;
  img {
    width: 2rem;
    height: 2rem;
    transition: 0.3s;
    cursor: pointer;
    filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(106deg)
      brightness(109%) contrast(101%);
    &:hover {
      filter: invert(34%) sepia(69%) saturate(2980%) hue-rotate(135deg)
        brightness(99%) contrast(105%);
    }
  }
}
.footer_addres {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 30%;
  @include breack_down(large) {
    width: 80%;
    text-align: center;
  }
  p,
  a {
    transition: 0.3s;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
    &:hover {
      color: $mainGreen;
    }
  }
  b {
    margin-bottom: 5px;
  }
}

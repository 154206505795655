@use "../App.scss" as *;
.faqs_cont {
  margin: 5rem 0;
  h3 {
    text-align: center;
  }
  .faqs_content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
    .faq {
      background-color: rgba(164, 241, 255, 0.308);
      width: 80vw;
      @include breack_down(medium) {
        width: 90vw;
      }
      padding: 1rem;
      border-radius: 10px;
      display: flex;
      align-items: flex-start;
      gap: 1rem;
      transition: 1s;
      .faq_text {
        width: 100%;
        transition: 1s;
        h4 {
          color: $mainBlue;
          font-size: 20px;
        }
      }
    }
  }
}
.plus {
  position: relative;
  width: 22px;
  height: 22px;
  cursor: pointer;
  flex-shrink: 0;
  .vertical {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 4px;
    height: 22px;
    background-color: $mainBlue;
    border-radius: 10px;
    transition: 0.3s ease-in;
  }
  .horizontal {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 22px;
    height: 4px;
    background-color: $mainBlue;
    border-radius: 10px;
  }
  .vertical_opened {
    transform: rotate(90deg);
  }
}
.ans_cont_grid {
  display: grid;
  grid-template-rows: 0fr;
  transition: 650ms;
  overflow: hidden;
}
.faq_answer {
  min-height: 0;
  color: $mainGrey;
}
.ans_cont_grid_v {
  grid-template-rows: 1fr;
}

.modules_cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    margin-bottom: 2rem;
  }
  .modules {
    .module_head {
      width: 90vw;
      @include breack_down(medium) {
        width: 100%;
      }
      background-color: rgb(197, 251, 255);
      margin-top: 1rem;
      padding: 0.7rem 1rem;
      border-radius: 10px;
      display: flex;
      align-items: center;
      gap: 1rem;
      .module_title {
        font-size: 18px;
      }
    }
    .module_list {
      max-height: 0px;
      overflow: hidden;
      transition: 650ms;
      .detail {
        display: flex;
        align-items: center;
        gap: 0.7rem;
        padding-left: 2rem;
        @include breack_down(medium) {
          padding-left: 1rem;
          align-items: flex-start;
        }
        .detail_dot {
          width: 7px;
          height: 7px;
          border-radius: 50%;
          background-color: rgb(255, 94, 0);
          flex-shrink: 0;
          @include breack_down(medium) {
            margin-top: 0.3rem;
          }
        }
      }
    }
  }
}
.see_all_modules {
  float: right;
  margin-top: 2rem;
  font-size: 20px;
  cursor: pointer;
  color: $mainBlue;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  box-shadow: rgba(57, 57, 219, 0.25) 0px 50px 100px -20px,
    rgba(35, 223, 18, 0.3) 0px 30px 60px -30px,
    rgba(14, 131, 248, 0.35) 0px -2px 6px 0px inset;

  @include breack_down(large) {
    margin-right: 5px;
  }
  &:hover {
    @include breack_up(medium) {
      color: $mainGreen;
    }
  }
}

@use "../../App.scss" as *;
.success_container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10vh;

  h2 {
    font-size: 44px;
    color: $mainBlue;
    font-weight: 800;
    // opacity: 0;
    animation: flipInX 1s forwards;
    text-align: center;
    @include breack_down(large) {
      font-size: 30px;
    }
  }
  h3 {
    font-size: 30px;
    width: 80%;
    text-align: center;
    color: $mainBlue;
    opacity: 0;
    animation: flipInX 1s 0.3s forwards, opacity 1s 0.3s forwards;
    @include breack_down(large) {
      font-size: 20px;
    }
  }
  h4 {
    font-size: 25px;
    width: 80%;
    text-align: center;
    color: $mainBlue;
    opacity: 0;
    animation: flipInX 1s 0.6s forwards, opacity 1s 0.6s forwards;
    @include breack_down(large) {
      font-size: 18px;
    }
  }
  a {
    text-decoration: none;
    color: #fff;
    background-color: $mainBlue;
    padding: 3px 12px;
    border-radius: 5px;
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@use "../App.scss" as *;

.reg_cont {
  margin-top: 10rem;
  padding: 7rem 3rem;
  width: 100%;
  height: fit-content;
  background-image: url("../images/register.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: space-between;
  @include breack_down(large) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100vw;
    gap: 1rem;
    padding: 3rem;
    margin-top: 5rem;
  }
}
.reg_text {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  text-align: center;
  color: rgb(0, 0, 0);
  align-items: center;

  h2 {
    font-size: 3.5rem;
    @include breack_down(medium) {
      font-size: 2rem;
    }
  }
  p {
    width: 70%;
    font-size: 2rem;
    @include breack_down(medium) {
      width: 90%;
      font-size: 1.2rem;
    }
  }
  @include breack_down(medium) {
    gap: 1rem;
  }
}
.reg_form {
  background-color: #fff;
  padding: 2rem;
  margin-right: 3rem;
  border-radius: 20px;
  height: fit-content;
  width: 34vw;
  @include breack_down(large) {
    width: 90vw;
    margin: 1rem 0 0 0;
    padding: 1rem;
  }
  h2 {
    color: $mainBlue;
    margin-bottom: 3rem;
    span {
      color: $mainRed;
    }
  }
}
.reg_form_radio {
  h3 {
    color: $darkBlue;
    margin-bottom: 0.7rem;
  }
  .reg_form_radio_btns_cont {
    display: flex;
    gap: 3rem;
    margin-bottom: 2rem;
    .reg_form_radio_btn {
      display: flex;
      gap: 1rem;
    }
    p {
      color: $grayBlue;
    }
    .reg_btn {
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      border: 3px solid $grayBlue;
      cursor: pointer;
    }
  }
}
.reg_form_imput {
  position: relative;
  input {
    width: 100%;
    margin-bottom: 2rem;
    padding: 1rem;
    border-radius: 5px;
    border: none;
    background-color: $grey;
    color: $mainBlue;
  }
}
.reg_form_main_btns {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  .continue_btn {
    @include breack_down(medium) {
      padding: 1rem;
      font-size: 12px;
    }
    background-color: rgb(245, 7, 7);
    color: #fff;
    border: none;
    padding: 1rem;
    cursor: pointer;
    border-radius: 10px;
    transition: 0.6s;
    &:hover {
      opacity: 0.7;
    }
  }
  .reg_form_btn {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: rgb(245, 7, 7);
    @include breack_down(medium) {
      font-size: 12px;
    }
    img {
      @include breack_down(medium) {
        width: 1rem;
        height: 1rem;
      }
      width: 2rem;
      height: 2rem;
      filter: invert(24%) sepia(69%) saturate(6331%) hue-rotate(352deg)
        brightness(88%) contrast(122%);
    }
    cursor: pointer;
    transition: 0.6s;
    &:hover {
      opacity: 0.7;
    }
  }
}
.reg_form_loading {
  margin: 0 1rem;
}
.reg_success {
  width: 2.7rem;
  height: 2.7rem;
  filter: invert(36%) sepia(35%) saturate(5282%) hue-rotate(135deg)
    brightness(99%) contrast(103%);
}
.mb-1 {
  position: relative;
}
.error_input_p2 {
  margin-bottom: 2rem;
  position: absolute;
  color: red;
  bottom: 0;
  transform: translateY(100%);
  @include breack_down(medium) {
    font-size: 13px;
  }
}

@use "../App.scss" as *;
.clh {
  min-height: 150vh;
}
.coursesL_hero {
  display: flex;
  margin-top: 12rem;
  align-items: center;
  justify-content: space-between;
  height: calc(100vh - 12rem);
  padding: 0 4rem;
  @include breack_down(large) {
    margin-top: 10rem;
    flex-direction: column;
    height: auto;
    width: 100%;
    gap: 48px;
    margin-bottom: 5rem;
  }
  @include breack_down(medium) {
    // padding-top: 8rem;
  }
  .course_hero_img_l {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    width: 40vw;

    @include breack_down(large) {
      display: none !important;
    }
  }
  .course_hero_img_l_2 {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    width: 90vw;
    @include breack_up(medium) {
      display: none !important;
    }
  }
  .clh_titel {
    margin-left: 4rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    // max-width: 70vw;
    @include breack_down(large) {
      max-width: 100%;
      margin-left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    h2 {
      color: $mainBlue;
      font-size: 80px;
      @include breack_down(large) {
        font-size: 40px;
      }
    }
    p {
      font-size: 18px;
      @include breack_down(large) {
        max-width: 100%;
        font-size: 16px;
      }
    }
    button {
      font-size: 16px;
      padding: 10px 15px;
      width: fit-content;
      background-color: $mainBlue;
      border: none;
      outline: 1px solid $mainBlue;
      color: #fff;
      cursor: pointer;
      transition: 0.5s;
      &:hover {
        background-color: #fff;
        color: $mainBlue;
      }
    }
  }
}
.l_courses_register {
  // width: 50vw;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  padding: 2rem;
  border-radius: 10px;
  @include breack_down(large) {
    padding: 1rem;
    // width: 100%;
  }
  .l_form_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    width: 100%;
    p {
      color: $mainBlue;
      font-size: 25px;
      font-weight: 500;
      @include breack_down(medium) {
        font-size: 16px;
      }
    }
    .l_form_header_logo {
      width: 10rem;
      @include breack_down(medium) {
        width: 8rem;
      }
    }
    .l_form_header_close {
      width: 3rem;
      cursor: pointer;
      @include breack_down(medium) {
        width: 2rem;
      }
    }
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 2.2rem;
    width: 40vw;
    @include breack_down(large) {
      width: 90vw;
    }
    .l_courses_inp_div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.2rem;
      position: relative;
      color: $mainBlue;
      label {
        font-weight: 500;
      }
      input {
        width: 100%;
        padding: 1rem;
        border-radius: 7px;
        border: none;
        outline: 1px solid $mainBlue;
        width: 30vw;
        @include breack_down(large) {
          width: 60vw;
          padding: 0.7rem;
        }
      }
      p {
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translateY(110%);
        color: red;
      }
    }
    .l_course_register {
      display: flex;
      gap: 3rem;
      align-items: center;
      img {
        width: 2.5rem;
        filter: invert(88%) sepia(20%) saturate(1365%) hue-rotate(94deg)
          brightness(99%) contrast(94%);
      }
      button {
        width: fit-content;
        padding: 0.7rem 1.2rem;
        background-color: $mainRed;
        border: none;
        border-radius: 7px;
        outline: 1px solid $mainRed;
        color: #fff;
        cursor: pointer;
        transition: 0.5s;
        @include breack_down(large) {
          padding: 0.5rem 1rem;
        }
        &:hover {
          background-color: #fff;
          color: $mainRed;
        }
      }
    }
  }
}
.l_course_container {
  background-color: #fff;
}
.l_courses_sticky {
  position: fixed;

  width: 100%;
  height: 3rem;
  background-color: rgb(1, 6, 51);
  bottom: 0rem;
  z-index: 1000;
  text-align: left;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  &:hover {
    background-color: rgb(2, 18, 156);
  }
}

.l_course_hero {
  img {
    width: 100%;
    @include breack_down(medium) {
      height: 14rem;
      object-fit: cover;
    }
  }
}
.how_works_container {
  margin: 7rem;
  @include breack_down(large) {
    margin: 5rem 2rem 5rem 2rem;
  }
  @include breack_down(medium) {
    margin: 5rem 1rem 5rem 1rem;
  }
  h2 {
    text-align: center;
    margin-bottom: 3rem;
  }
  .how_works_cont {
    // margin: 7rem;
    display: flex;
    gap: 5rem;
    justify-content: center;
    @include breack_down(large) {
      flex-direction: column;
    }
    .how_item {
      width: 27%;
      @include breack_down(large) {
        width: 100%;
      }
      background-color: #e4fdfe;
      border-radius: 10px;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      img {
        width: 2.2rem;
      }
      .find_img {
        filter: invert(16%) sepia(82%) saturate(3458%) hue-rotate(346deg)
          brightness(103%) contrast(105%);
      }
      .book_img {
        filter: invert(22%) sepia(98%) saturate(2483%) hue-rotate(189deg)
          brightness(89%) contrast(107%);
      }
      .cert_img {
        filter: invert(41%) sepia(90%) saturate(2246%) hue-rotate(124deg)
          brightness(91%) contrast(101%);
      }
      .how_text {
        h3 {
          margin-bottom: 1rem;
          // text-align: center;
          // color: $grayGreen;
        }
      }
    }
  }
}
.single_course_l {
  display: flex;
  flex-direction: column;
  gap: 6rem;
  background-color: #e4fdfe;
}
.course_html_text {
  padding: 1rem 4rem;

  @include breack_down(large) {
    padding: 1rem;
  }
  h4 {
    margin-bottom: 1rem;
  }
  h2 {
    margin-bottom: 1rem;
  }
  p {
    color: $mainGrey;
    margin: 1rem 0;
  }
  h3,
  h2,
  h4 {
    margin: 1rem 0;
    display: block;
    color: $mainBlue;
    background-color: rgba(164, 241, 255, 0.308);
    padding: 1rem;
  }
  ul {
    margin-left: 3rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: $mainGrey;
  }
}
.recomended_courses_L {
  width: 100%;
  overflow: hidden;
  gap: 2rem;
  background-color: #e4fdfe;
  margin: 3rem 0 3rem 0;
  h2 {
    margin: 3rem 0;
    width: 100%;
    text-align: center;
  }
  .rec_relative_cont {
    position: relative;
    left: 5vw;

    @include breack_down(medium) {
      left: 10vw;
    }
  }
  .courses_rec_cont {
    display: flex;
    gap: 5rem;
    position: relative;
    transition: 1s;
    width: fit-content;
    .courses_rec_item {
      background-color: #fff;
      text-decoration: none;
      border-radius: 5px;
      width: 25vw;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-decoration: none;
      @include breack_down(large) {
        width: 40vw;
      }
      @include breack_down(medium) {
        width: 80vw;
      }
      .rec_yeses {
        .rec_yes {
          margin-bottom: 5px;
          display: flex;
          gap: 5px;
          img {
            width: 1.2rem;
          }
        }
      }
      .course_rec_text {
        h3 {
          text-align: center;
          margin: 1rem 0;
        }
      }
      img {
        width: 100%;
        border-radius: 5px 5px 0 0;
      }
    }
  }

  .rec_btns {
    width: 100%;
    justify-content: center;
    margin: 3rem 0;
    display: flex;
    gap: 2rem;
  }
}
.course_title {
  text-align: center;
  display: block;
  padding-top: 4rem;
  padding-bottom: 1rem;
  background-color: #e4fdfe;
  font-size: 40px;
}

.landing_course_batches {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  background-color: $mainBlue;
  padding: 3rem 0;
  h2 {
    color: #fff;
  }
  .l_batch_container {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 5rem;
    @include breack_down(large) {
      width: 100%;
    }
    .l_batch {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      background-color: #fff;
      border-radius: 7px;
      width: 100%;
      @include breack_down(medium) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px 10px;
      }
      .batch_info {
        width: 20%;
        @include breack_down(medium) {
          width: fit-content;
        }
        h4 {
          margin-bottom: 10px;
          color: $grayGreen;
        }
        p {
          color: $mainGrey;
        }
      }
      button {
        border: none;
        padding: 0.7rem 1rem;
        width: fit-content;
        border-radius: 7px;
        cursor: pointer;
        color: #fff;
        background-color: $mainBlue;
        font-size: 16px;
        transition: 0.5s;
        outline: 1px solid $mainBlue;
        &:hover {
          color: $mainBlue;
          background-color: #fff;
        }
      }
    }
  }
}
